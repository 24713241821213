<template>
  <div class="container-fluid feeds">
    <div class="col-md-2 hidden-xs hidden-sm"></div>
    <div class="col-md-8 col-sm-12">
      <div class="">
        <div class="my-bets">
          <div class="my-bets-nav">
            <span :class="{'selected-bet': type == 'Normal'}" @click=" selectType('Normal')">{{ $t('betslip.normal') }}</span>
            <!-- <span :class="{'selected-bet': type == 'Jackpot'}" @click=" selectType('Jackpot')">{{ $t('betslip.jackpot') }}</span> -->
            <span :class="{'selected-bet': type == 'Virtuals'}" @click=" selectType('Virtuals')">{{ $t('casino.virtuals') }}</span>
            <span :class="{'selected-bet': type == 'Casino'}" @click=" selectType('Casino')">{{ $t('casino.casino') }}</span>
          </div>
          <div class="featured-search text-left">
            <h5>{{ $t('betslip.search-by-date') }}</h5>
            <date-picker
                v-model="time"
                valueType="format"
                :range=true
                format="YYYY-MM-DD"
                type="format"
                placeholder=""
                ref="datepicker"
            ></date-picker>
          </div>
          <Loader v-if="load"/>
          <div class="my-bets-body" v-if="normal.length == 0 && !load">
            <Alerts type="alert-info" :message=" $t('betslip.no-bets')" />
          </div>
          <div class="my-bets-body" v-if="!load">
            <div class="my-bets-section" v-for="(slip,i) in normal" :key="i" @click="showBetslip(slip.bet_id)">
              <div class="">
                <router-link :to="`/single-bet/${slip.bet_id}`" tag="p"><b>#{{ slip.bet_code}}</b></router-link>
                <p>{{ slip.date_placed | moment('D/MM HH:mm') }}</p>
              </div>
              <div class="text-right">
                <p>{{ getCurrency() }} {{slip.possible_win.toFixed(0) }}</p>
                <p class="text-success" v-if="slip.won && slip.status && slip.cancelled == null">{{ $t('betslip.win') }}</p>
                <p class="text-danger" v-if="!slip.won && slip.status && slip.cancelled == null">{{ $t('betslip.lost') }}</p>
                <p class="text-orange" v-if="!slip.won && !slip.status && slip.cancelled == null">{{ $t('betslip.running') }}</p>
              </div>
            </div>
            <div class="text-center paginated-bets" v-if="meta">
                <div class="">
                  <i class="icofont-rounded-left" @click="goNext(previous_page)" ></i>
                  {{ $t('betslip.bets') }}
                  <i class="icofont-rounded-right" @click="goNext(next_page)"></i>
                </div>
            </div>
          </div>
          
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Loader from "@/components/sections/loader.vue";
import {mapGetters,mapActions} from "vuex";
import Alerts from "@/components/sections/betslip-sections/alert";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import countryToCurrency from "country-to-currency";


export default {
  name: 'all-bets',
  data() {
    return {
      time:'',
      to: null,
      from: null,
      load: false,
      options: {
        texts: {
          count:''
        }
      },
      bettype:'',
      type:'Normal'
    }
  },
  watch: {
    time: async function(date) {
      this.bettype = 'date'
      this.load = true
      this.from = date[0]
      this.to = date[1]

      let dates = {
          from: date[0],
          to: date[1]
      }

      if(dates.from && dates.to) {
        await this.get_by_date(
          {
            date:dates,
            post:{
              "page":0,
              "size":15
            }
          }
        );
      }
      this.load = false;
    }
  },
  props: {
    msg: String
  },
  computed:{
    ...mapGetters({
      betslip: 'bets',
      meta: 'meta',
      user:'user'
    }),
    normal() {
      return (this.betslip) ? this.betslip.filter(x => x.type == this.type) : [];
    },
    next_page() {
      return this.meta.page || 0
    },
    previous_page() {
      return (this.meta.current == 0) ? 0 : this.meta.current - 1;
    }
  },
  components: {
    Loader,
    Alerts,
    DatePicker
  },
  mounted() {
    this.getBets();
    this.$store.dispatch("clear_single_betmatches")
  }, 
  methods: {
    ...mapActions({
      get_by_date: "get_user_bets_by_date",
    }),
    getBets: async function() {
      this.load = true
      this.bettype = 'normal';
      await this.$store.dispatch("get_user_bets",{
        post:{
          "page":0,
          "size":15
        },
        type:'new'
      })
      this.load = false
    },
    getNext: async function(page) {

      let dates = null
      if (this.to) {
        dates = {
          to: this.to,
          from: this.from
        }
      } else {
        dates = null
      }

      let cur = {
        path: this.meta.path + "?page=" + page,
        type: this.meta.type,
        time: dates
      }
      
      this.$Progress.start()
      this.load = true
      await this.$store.dispatch("fetch_pagination_bets",cur)
      this.load = false
      window.scrollTo(0,0) 
      this.$Progress.finish()
    },
    showBetslip(bet_id) {
      this.$router.push(`/single-bet/${bet_id}`)
    },
    selectType: function(type_) {
      this.type = type_
    },
    goNext: async function(count) {
      this.load = true
      if(this.bettype == 'normal') {
        await this.$store.dispatch("get_user_bets",{
          post:{
            "page":count,
            "size":15
          },
          type:'new'
        })
      } else {
        await this.get_by_date(
          {
            date:this.meta.date,
            post:{
              "page":count,
              "size":15
            }
          }
        );
      }
      this.load = false;
    },
    getCurrency: function() {
      return countryToCurrency[this.user.iso];
    }
  },
}
</script>
<style scoped>
.paginated-bets {
  padding: 10px;
  margin-top: 15px;
}
.paginated-bets i{
  margin-right: 0px;
  cursor: pointer;
}
.featured-search {
  padding: 10px 0px;
}
</style>